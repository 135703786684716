<template>
    <div class="community">
<!--        导航-->
        <van-nav-bar @click-left="leftReturn" title="选择小区"  left-arrow fixed/>
        <!--            搜索栏-->
        <div class="searchDiv">
            <van-search v-model="searchName" class="serch" placeholder="请输入搜索关键词" @input="initData"/>
        </div>
        <div class="communityDiv">
<!--            最近浏览-->
            <div class="recentBrowseDiv">
                <span class="recentBrowseText">最近浏览</span>
                <ul>
                    <li  @click="selectHistoryRecord({row:recentBrowseInfo})" class="recentBrowseLi" v-for="recentBrowseInfo in recentBrowseInfos">
                        {{recentBrowseInfo ? recentBrowseInfo.searchName : ''}}
                    </li>
                </ul>
            </div>
<!--            分割线-->
            <div class="rule"></div>
<!--            模糊显示-->
            <div class="likeShowDiv">
                <ul >
                    <li  @click="change(likeShowInfo)"  class="likeShowLi" v-for="(likeShowInfo) in likeShowInfos">
                        {{ likeShowInfo.estateName }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { NavBar,Search,Icon,Area,Col, Row  } from 'vant';
    import {queryEstateList} from "../../../getData/getData";
    import {responseUtil} from "../../../libs/rongxunUtil";
    import eventBus from "../../../components/rongxun/rx-navBar/envbus.js"
    export default {
        components: {
            [NavBar .name]:NavBar,
            [Search .name]:Search,
            [Icon .name]:Icon,
            [Area .name]:Area,
            [Col .name]:Col,
            [Row .name]:Row
        },
        data() {
            return {
                searchName:'',
                searchId:'',
                address :'瓦房店士',
                recentBrowseInfos : [

                ],
                likeShowInfos : [],
            }
        },

        mounted() {
            this.initData()
            this.getLocalBrowseRecord();
        },
        methods:{
            clear(){
              localStorage.clear()
            },
            //返回上一层
            leftReturn(){
                let params = {
                    searchInfo:this.searchName,
                    searchId:this.searchId
                }
                //  //传递一个map，chooseCommunity是key，params是value
                eventBus.$emit('chooseCommunity',params);
                //调用router回退页面
                this.$router.go(-1);
            },

            // 页面初始化
            initData: function () {
                var that = this
                let initData = {}
                initData.searchName = that.searchName;
                queryEstateList(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response.data.data.roomHouseList)
                        that.likeShowInfos = response.data.data.roomHouseList
                    })
                })

            },
            change(likeShowInfo) {
                let that = this;
                that.searchName = likeShowInfo.estateName;
                that.searchId = likeShowInfo.id
                let searchInfo = {}
                searchInfo.searchName = likeShowInfo.estateName
                searchInfo.searchId = likeShowInfo.id

                // 将选择的小区保存到本地用作最近浏览数据
                //如果local里不存在当前输入的关键词 则存到local里
                for(let i = 0; i < that.recentBrowseInfos.length; i++){
                    let newSearchId = likeShowInfo.id
                    let oldSearchId = that.recentBrowseInfos[i].searchId
                    if(newSearchId == oldSearchId){
                        that.recentBrowseInfos.splice(i,1)
                        break
                    }
                }
                that.recentBrowseInfos.unshift(searchInfo)
                    //历史记录最多只取五个
                that.recentBrowseInfos = that.recentBrowseInfos.slice(0,5)
                localStorage.setItem('recentBrowseInfos',JSON.stringify(that.recentBrowseInfos))
                // }
                //返回到添加潜房
                this.leftReturn();
            },
            //选择历史记录
            selectHistoryRecord(e){
                let that = this;
                that.searchName = e.row.searchName
                that.searchId = e.row.searchId
                let searchInfo = {}
                searchInfo.searchName = that.searchName
                searchInfo.searchId = that.searchId

                // 将选择的小区保存到本地用作最近浏览数据
                //如果local里不存在当前输入的关键词 则存到local里
                if(that.searchName == '') return
                for(let i = 0; i < that.recentBrowseInfos.length; i++ ){
                    let newSearchId = e.row.searchId
                    let oldSearchId = that.recentBrowseInfos[i].searchId
                    if(newSearchId == oldSearchId){
                        that.recentBrowseInfos.splice(i,1)
                        break
                    }
                }
                that.recentBrowseInfos.unshift(searchInfo)
                that.recentBrowseInfos = that.recentBrowseInfos.slice(0,5)
                localStorage.setItem('recentBrowseInfos',JSON.stringify(that.recentBrowseInfos))
                // }
                //返回到添加潜房
                this.leftReturn();
            },

            //获取本地浏览记录
            getLocalBrowseRecord(){
                this.recentBrowseInfos = JSON.parse(localStorage.getItem('recentBrowseInfos')) || []; //先读取local里存储的历史记录
            }
        }
    }
</script>

<style lang="less" scoped >
    *{
        margin: 0;
        padding: 0;
    }
    /*总DIV */
    .communityDiv{
        margin: 115px auto;
        width: 92%;
    }
    .community:after{
        content: "";
        display: block;
        clear: both;
    }
    /*搜索框DIV*/
    .searchDiv{
        line-height: 40px;
        width:100%;
        overflow: hidden;
        .serch{
            width: 92%;
            margin: 0 auto;
            border-radius: 20px;
        }
    }
    /*位置字体*/
    .searchText{
        font-size: 15px;
        font-weight: bold;
        float: left;
        margin-top: 3px;
        margin-left: 0px;
        width: 50px;
    }
/*    最近浏览*/
    .recentBrowseText{
        font-size: 13px;
        font-weight: bold;
    }
/*    最近浏览信息*/
    .recentBrowseLi{
        font-size: 14px;
        color: #999999;
        margin-top: 15px;
    }
/*    分割线*/
    .rule{
        width: 100%;
        height: 0.1px;
        border: 0.8px solid #f0f0f0;
        margin-top: 15px;
        margin-bottom: 28px;
    }
/*    模糊显示信息*/
    .likeShowLi{
        font-size: 15px;
        margin-top: 15px;
    }
</style>
